import React from "react";
import Helmet from "react-helmet";
import { StaticQuery, graphql } from "gatsby";

interface SeoProps {
  postData?: {
    frontmatter?: any
  }
  frontmatter?: {}
  postImage?: string
  isBlogPost?: boolean
}

const SEO = ({
  postData = {},
  frontmatter = {},
  postImage = "",
  isBlogPost = false,
}: SeoProps) => (
  <StaticQuery
    query={SeoQuery}
    render={({ site: { siteMetadata: seo } }) => {
      const postMeta =
        frontmatter || postData?.frontmatter || {};

      const title = postMeta.title || seo.title;
      const description = postMeta.description || seo.description;
      const image = postImage ? `${seo.siteUrl}${postImage}` : seo.image;
      const url = postMeta.slug
        ? `${seo.siteUrl}/${postMeta.slug}/`
        : seo.siteUrl;

      return (
        <>
          <Helmet>
            {/* General tags */}
            <title>{title}</title>
            <meta name="description" content={description} />
            <meta name="image" content={image} />
            <link rel="canonical" href={url} />

            {/* OpenGraph tags */}
            <meta property="og:url" content={url} />
            {isBlogPost ? <meta property="og:type" content="article" /> : null}
            <meta property="og:title" content={title} />
            <meta property="og:description" content={description} />
            <meta property="og:image" content={image} />

            {/* Twitter Card tags */}
            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:creator" content={seo.social.twitter} />
            <meta name="twitter:title" content={title} />
            <meta name="twitter:description" content={description} />
            <meta name="twitter:image" content={image} />
            <script type="module" src="https://unpkg.com/@deckdeckgo/highlight-code@latest/dist/deckdeckgo-highlight-code/deckdeckgo-highlight-code.esm.js"></script>
          </Helmet>
        </>
      );
    }}
  />
);

const SeoQuery = graphql`
query DefaultSeoQuery {
  site {
    siteMetadata {
      title
      description
      siteUrl
      image
      author {
        name
      }
      social {
        twitter
      }
    }
  }
}
`

export default SEO;
